/* You can add global styles to this file, and also import other style files */
@import "@mhe/mh-icon/src/mhicon.css";
@import "@mhe/he-bs-themes/src/5/up-bs5/index.scss";

* {
  font-family: $font-proxima-nova;
}

mjx-container * {
  font-family: MJXZERO, MJXTEX !important;
}

body::-webkit-scrollbar {
  width: 0.625rem;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.375rem $black-04;
}

body::-webkit-scrollbar-thumb {
  background-color: $grey-07;
  outline: 0.063rem solid $grey-07;
  border-radius: 0.5rem;
}

a {
  color: $blue-01;
}

body {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
}
